<template>
  <div class="white">
    <div class="row">
      <div class="col s12 m6 xl4">
        <line-chart
          v-if="loaded"
          :chartdata="chartUsers"
          :options="optionsStacked"/>
      </div>
      <div class="col s12 m6 xl4">
        <line-chart
          v-if="loaded"
          :chartdata="chartArticles"
          :options="optionsStacked"/>
      </div>
      <div class="col s12 m6 xl4">
        <line-chart
          v-if="loaded"
          :chartdata="chartFeatures"
          :options="optionsStacked"/>
      </div>
    </div>
  </div>
</template>

<script>
import LineChart from '@/components/LineChart'
import shared from '../shared'
// import { store } from '../store'

export default {
  name: 'Statistics',
  components: { LineChart },
  data: () => ({
    loaded: false,
    chartUsers: null,
    chartArticles: null,
    chartFeatures: null,
    options: { }, // backgroundColor: '#fff' },
    optionsStacked: { scales: { yAxes: [{ stacked: true }] } } // backgroundColor: '#fff' }
  }),
  async mounted () {
    this.loaded = false
    try {
      const usersStatistics = await shared.get('statistics/days/users')
      const articlesStatistics = await shared.get('statistics/days/articles')
      const featuresStatistics = await shared.get('statistics/days/features')
      this.chartUsers = usersStatistics.data
      this.chartArticles = articlesStatistics.data
      this.chartFeatures = featuresStatistics.data
      this.loaded = true
    } catch (e) {
      console.error(e)
    }
  }
}
</script>

<style scoped>
.white {
    background-color: azure;
    height: 100%;
}
</style>
